import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Coming soon... <br />
          Roomies Network, Inc. <br />
          Phone Number: +1 7602848466
        </p>
      </header>
    </div>
  );
}

export default App;
